.app {
  text-align: center;
}

.big_text {
  font-size: 30px;
  color: red;
  margin: 0 10px;
}

.image_area img,
video {
  width: 100%;
  max-width: 1024px
}