.require label::before {
    content: "必須";
    background: #ff4d1f;
    color: #fff;
    margin-right: 5px;
    padding: 1px 6px;
    font-size: 12px;
}

.hidden {
    display: none;
}