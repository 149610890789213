.issuerName {
    display: flex;
    align-items: center;
    margin: 20px 30px;

    & span {
        margin-left: 20px;
        font-size: 24px;
    }
}

.scan_video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.scan_canvas {
    visibility: hidden;
}